import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import type { Theme } from "@mui/material/styles/createTheme";
import clsx from "clsx";
import React from "react";

import { config } from "../../config";
import { CheckCircleIcon } from "../../icons/checkCircleIcon";
import { LeftHeaderOverride, RightHeaderOverride } from "../../models/headerOverride";
import { BasePageContext } from "../../state/basePageContext";
import { makeStyles } from "../../styles/makeStyles";
import { clearClientPublicToken } from "../../util/cookies";
import { getImageUrl } from "../../util/img";
import { createUrl } from "../../util/url";
import { BackHeaderButton } from "./backHeaderButton";
import { RightHeaderButtons } from "./rightHeaderButtons";
import { SessionButton } from "./sessionButton";

export const getHeaderHeight = (theme: Theme) => theme.spacing(10.5);
export const getSmallHeaderHeight = (theme: Theme) => theme.spacing(8);

const useStyles = makeStyles<{
    clear: boolean;
    leftHeaderOverrideBreakpoint?: number;
    rightHeaderOverrideBreakpoint?: number;
}>()((theme, { clear, leftHeaderOverrideBreakpoint, rightHeaderOverrideBreakpoint }) => ({
    appBar: {
        backgroundColor: clear ? "transparent" : theme.palette.background.paper,
        borderRadius: 0,
        boxShadow: "none",
        justifyContent: "center",
        borderBottom: clear ? undefined : `1px solid ${theme.palette.divider}`,
        height: getHeaderHeight(theme),
        "@media (max-width: 500px)": {
            height: getSmallHeaderHeight(theme),
        },
    },
    toolbar: {
        width: "100%",
        justifyContent: "center",
        padding: `0 ${theme.spacing(5)}`,
        minHeight: getHeaderHeight(theme),
        "@media (max-width: 500px)": {
            minHeight: getSmallHeaderHeight(theme),
            padding: `0 ${theme.spacing(3)}`,
        },
    },
    toolbarContent: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
    },
    leftHeaderItemOverride: {
        ...(leftHeaderOverrideBreakpoint !== undefined
            ? {
                  [`@media (min-width: ${leftHeaderOverrideBreakpoint + 1}px)`]: {
                      display: "none",
                  },
              }
            : {}),
    },
    logo: {
        lineHeight: 0,
        ...(leftHeaderOverrideBreakpoint !== undefined
            ? {
                  [`@media (max-width: ${leftHeaderOverrideBreakpoint}px)`]: {
                      display: "none",
                  },
              }
            : {}),
    },
    logoType: {
        "@media (max-width: 500px)": {
            display: "none",
        },
    },
    logoMark: {
        "@media (min-width: 501px)": {
            display: "none",
        },
    },
    rightHeaderItemOverride: {
        ...(rightHeaderOverrideBreakpoint !== undefined
            ? {
                  "&.right-header-buttons": {
                      [`@media (min-width: ${rightHeaderOverrideBreakpoint + 1}px)`]: {
                          display: "none",
                      },
                  },
              }
            : {}),
    },
    sessionButton: {
        ...(rightHeaderOverrideBreakpoint !== undefined
            ? {
                  [`@media (max-width: ${rightHeaderOverrideBreakpoint}px)`]: {
                      display: "none",
                  },
              }
            : {}),
    },
    rightHeaderOverride: {
        display: "flex",
        alignItems: "center",
    },
    rightHeaderOverrideMessageWithButton: {
        marginRight: theme.spacing(2),
    },
    rightHeaderMessageOverride: {
        ...(rightHeaderOverrideBreakpoint !== undefined
            ? {
                  [`@media (min-width: ${rightHeaderOverrideBreakpoint + 1}px)`]: {
                      display: "none",
                  },
              }
            : {}),
        color: theme.palette.text.primary,
        display: "flex",
        alignItems: "center",
        fontSize: "1.0rem",
    },
    checkCircle: {
        margin: `0 ${theme.spacing(1)}`,
        fontSize: "21px",
    },
}));

interface Props {
    clear?: boolean;
    leftHeaderOverride?: LeftHeaderOverride;
    rightHeaderOverride?: RightHeaderOverride;
    onLogout?: () => void;
}

export const BasePageHeader: React.FC<Props> = React.memo(
    ({ clear = false, leftHeaderOverride, rightHeaderOverride, onLogout }) => {
        const { publicToken, infoToast, errorToast } = React.useContext(BasePageContext);
        const { classes } = useStyles({
            clear,
            leftHeaderOverrideBreakpoint: leftHeaderOverride ? 1128 : undefined,
            rightHeaderOverrideBreakpoint: rightHeaderOverride ? 900 : undefined,
        });

        return (
            <AppBar className={classes.appBar} position="sticky">
                <Toolbar className={classes.toolbar}>
                    <div className={classes.toolbarContent}>
                        {!!leftHeaderOverride && (
                            <BackHeaderButton
                                className={classes.leftHeaderItemOverride}
                                link={leftHeaderOverride.link}
                            />
                        )}
                        <>
                            <a
                                className={clsx(classes.logo, classes.logoType)}
                                href={createUrl(config.webHost, publicToken ? { p: publicToken } : undefined)}
                                onClick={clearClientPublicToken}
                            >
                                <img
                                    src={getImageUrl("/assets/cymbal-logotype-light.webp", { height: 120 })}
                                    height={24}
                                    alt="Cymbal logotype"
                                />
                            </a>
                            <a
                                className={clsx(classes.logo, classes.logoMark)}
                                href={createUrl(config.webHost, publicToken ? { p: publicToken } : undefined)}
                                onClick={clearClientPublicToken}
                            >
                                <img
                                    src={getImageUrl("/assets/cymbal-logo-light.webp", { height: 120 })}
                                    height={23.4}
                                    alt="Cymbal logo"
                                />
                            </a>
                        </>

                        {!!rightHeaderOverride && (
                            <div className={classes.rightHeaderOverride}>
                                {!!rightHeaderOverride.primaryMessage && (
                                    <div
                                        className={clsx(
                                            classes.rightHeaderMessageOverride,
                                            rightHeaderOverride.primaryMessage.withButton ?? false
                                                ? classes.rightHeaderOverrideMessageWithButton
                                                : undefined,
                                        )}
                                    >
                                        <CheckCircleIcon className={classes.checkCircle} />
                                        {rightHeaderOverride.primaryMessage.message}
                                    </div>
                                )}
                                {(!rightHeaderOverride.primaryMessage ||
                                    (rightHeaderOverride.primaryMessage?.withButton ?? false)) && (
                                    <RightHeaderButtons
                                        className={classes.rightHeaderItemOverride}
                                        primary={{
                                            title: rightHeaderOverride.primary.title,
                                            isDisabled: rightHeaderOverride.primary.isDisabled,
                                            isLoading: rightHeaderOverride.primary.isLoading,
                                            color: rightHeaderOverride.primary.color,
                                            onClick: () =>
                                                rightHeaderOverride.primary.onClick({ infoToast, errorToast }),
                                        }}
                                        secondary={
                                            rightHeaderOverride.secondary
                                                ? {
                                                      title: rightHeaderOverride.secondary.title,
                                                      isDisabled: rightHeaderOverride.secondary.isDisabled,
                                                      isLoading: rightHeaderOverride.secondary.isLoading,
                                                      onClick: () => {
                                                          const { secondary } = rightHeaderOverride;
                                                          if (!!secondary) {
                                                              secondary.onClick({ infoToast, errorToast });
                                                          }
                                                      },
                                                  }
                                                : undefined
                                        }
                                    />
                                )}
                            </div>
                        )}

                        <SessionButton
                            className={classes.sessionButton}
                            publicToken={publicToken}
                            onLogout={onLogout}
                        />
                    </div>
                </Toolbar>
            </AppBar>
        );
    },
);
