import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import clsx from "clsx";
import React from "react";

import { config } from "../../config";
import { ProfileIcon } from "../../icons/profileIcon";
import { GitbookTokenInfo } from "../../models/gitbookTokenInfo";
import { AppContext } from "../../state/appContext";
import { makeStyles } from "../../styles/makeStyles";
import { clientApiPost } from "../../util/api";
import {
    clearClientGitbookTokenInfo,
    clearClientPublicToken,
    clearClientSessionToken,
    setClientGitbookTokenInfo,
} from "../../util/cookies";
import { createUrl } from "../../util/url";

const useStyles = makeStyles()((theme) => ({
    profileIconButton: {
        "&:hover": {
            backgroundColor: "transparent",
        },
    },
    profileIcon: {
        fontSize: "1.335em",
        marginRight: theme.spacing(-1),
    },
    menu: {
        marginTop: theme.spacing(1),
    },
    menuPaper: {
        borderRadius: theme.spacing(2),
        minWidth: "240px !important",
    },
}));

interface Props {
    className?: string;
    publicToken: string | undefined;
    onLogout?: () => void;
}

export const SessionButton: React.FC<Props> = React.memo(({ className, publicToken, onLogout }) => {
    const { classes } = useStyles();
    const { userOwnsTickets, gitbookTokenInfo, resetUser, resetGitbookTokenInfo } = React.useContext(AppContext);

    const [menuElement, setMenuElement] = React.useState<HTMLButtonElement>();
    const closeMenu = () => setMenuElement(undefined);

    const onHelpCenterClicked = React.useCallback(
        async (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
            if (!!gitbookTokenInfo && new Date(gitbookTokenInfo.expiry).getTime() > new Date().getTime()) {
                return;
            }

            e.preventDefault();
            const newGitbookTokenInfo = await clientApiPost<GitbookTokenInfo>("/gitbooks/token");
            if (!!newGitbookTokenInfo) {
                setClientGitbookTokenInfo(newGitbookTokenInfo);
                resetGitbookTokenInfo(newGitbookTokenInfo);
                window.open(
                    createUrl("https://docs.cymbal.co/help", {
                        jwt_token: newGitbookTokenInfo.token,
                    }),
                    "_blank",
                );
            }
        },
        [gitbookTokenInfo, resetGitbookTokenInfo],
    );

    return (
        <>
            <IconButton
                className={clsx(className, classes.profileIconButton)}
                color="primary"
                onClick={(e) => setMenuElement(e.currentTarget)}
            >
                <ProfileIcon className={classes.profileIcon} />
            </IconButton>
            <Menu
                className={classes.menu}
                classes={{ paper: classes.menuPaper }}
                anchorEl={menuElement}
                open={Boolean(menuElement)}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                transformOrigin={{ vertical: "top", horizontal: "right" }}
                transitionDuration={0}
                onClose={closeMenu}
            >
                {userOwnsTickets && (
                    <MenuItem
                        component="a"
                        href={createWebLink("/tickets", publicToken, config.webHost)}
                        onClick={() => {
                            closeMenu();
                            clearClientPublicToken();
                        }}
                    >
                        My tickets
                    </MenuItem>
                )}
                <MenuItem
                    component="a"
                    href={createWebLink("/settings", publicToken, config.webHost)}
                    onClick={() => {
                        closeMenu();
                        clearClientPublicToken();
                    }}
                >
                    Settings
                </MenuItem>
                <MenuItem
                    component="a"
                    href={createUrl("https://docs.cymbal.co/help", { jwt_token: gitbookTokenInfo?.token ?? "" })}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={onHelpCenterClicked}
                >
                    Help center
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        closeMenu();
                        clearClientSessionToken();
                        clearClientPublicToken();
                        clearClientGitbookTokenInfo();
                        resetUser();
                        if (onLogout) {
                            onLogout();
                        }

                        window.location.href = createUrl(config.webHost, { clear_session: true });
                    }}
                >
                    <div>Log out</div>
                </MenuItem>
            </Menu>
        </>
    );
});

const createWebLink = (route: string, publicToken: string | undefined, webHost: string) =>
    createUrl(`${webHost}${route}`, publicToken ? { p: publicToken } : undefined);
