import Button, { ButtonProps } from "@mui/material/Button";
import clsx from "clsx";
import React from "react";

import { makeStyles } from "../styles/makeStyles";
import { LoadingButton, LoadingButtonProps } from "./loadingButton";

const useStyles = makeStyles()((theme) => ({
    smallButton: {
        height: 42,
        borderRadius: 21,
        padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
        fontSize: "0.875rem",
    },
}));

export const SmallButton = React.forwardRef<HTMLButtonElement, ButtonProps>(({ className, ...rest }, ref) => {
    const { classes } = useStyles();

    return <Button ref={ref} className={clsx(className, classes.smallButton)} {...rest} />;
});

export const SmallLoadingButton = React.forwardRef<HTMLButtonElement, LoadingButtonProps>(
    ({ className, ...rest }, ref) => {
        const { classes } = useStyles();

        return <LoadingButton ref={ref} className={clsx(className, classes.smallButton)} {...rest} />;
    },
);
