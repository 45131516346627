import clsx from "clsx";
import React from "react";

import { makeStyles } from "../../styles/makeStyles";
import { SmallLoadingButton } from "../smallButton";

const useStyles = makeStyles()((theme) => ({
    actions: {
        display: "flex",
        alignItems: "center",
    },
    secondaryAction: {
        marginRight: theme.spacing(1),
    },
}));

interface Action {
    isDisabled?: boolean;
    isLoading?: boolean;
    title: string;
    onClick: () => void;
}

interface PrimaryAction extends Action {
    color?: "primary" | "secondary";
}

interface Props {
    className?: string;
    primary: PrimaryAction;
    secondary?: Action;
}

export const RightHeaderButtons: React.FC<Props> = React.memo(({ className, primary, secondary }) => {
    const { classes } = useStyles();

    return (
        <div className={clsx(className, "right-header-buttons", classes.actions)}>
            {!!secondary && (
                <SmallLoadingButton
                    className={classes.secondaryAction}
                    disabled={secondary.isDisabled}
                    loading={secondary.isLoading}
                    onClick={secondary.onClick}
                    color="secondary"
                    variant="contained"
                >
                    {secondary.title}
                </SmallLoadingButton>
            )}
            <SmallLoadingButton
                disabled={primary.isDisabled}
                loading={primary.isLoading}
                onClick={primary.onClick}
                color={primary.color ?? "primary"}
                variant="contained"
            >
                {primary.title}
            </SmallLoadingButton>
        </div>
    );
});
